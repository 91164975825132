import { Link } from "gatsby"
import React from "react"

import "../styles/footer.css"

const Footer = () => (
  <footer className="common-width-style"> 
    <div id="footerContent"> 
      <div id="footerCopyright">
        <Link to="/" id="footerCopyrightLink">
          © {new Date().getFullYear()} &middot; Assignment Operator
        </Link>
      </div>
      <div id="footerMenu">
        {/* <Link to="/about/" className="footerMenuLink">About</Link>
        <span className="footerMenuDivider">&nbsp;|&nbsp;</span> */}
        <Link to="/contact/" className="footerMenuLink">get-in-touch</Link>
      </div>
    </div>
  </footer>
)

export default Footer
