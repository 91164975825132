import PropTypes from "prop-types"
import React from "react"

import Brand from "./brand";

import "../styles/header.css"
import logo from "../images/ao-logo-2.png"

const Header = ({ siteTitle }) => (
  <header className="common-width-style">
    <div id="headerContainer">
      <div id="headerBrand">
        <Brand brandName={ siteTitle } />
      </div>
      {/* <div id="headerNavigation">
        <Navigation />
      </div> */}
      <div id="headerLogo">
        <img alt="logo" src={logo} height={40}/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
