import { Link } from "gatsby"
import React from "react"

const LinkStyle = {
    color: `royalBlue`,
    textDecoration: `none`,
};
const Brand = ({ brandName }) => (
    <div> 
        <Link to="/" style={ LinkStyle } >
            {/* {"Assignment Operator"} */}
            { brandName }
        </Link>
    </div>
)

export default Brand