/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Navigation from "./navigation"
import "../styles/layout.css"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div id="mycontainer">
        <div id="myheader">
          <Header siteTitle={data.site.siteMetadata.title} />
        </div>
        <div id="mynavigation">
          <Navigation />
        </div>
        <div id="mymain" >
          <main className="common-width-style">
            {children}
          </main>
        </div>
        <div id="myfooter">
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
