import { Link } from "gatsby"
import React from "react"

import "../styles/navigation.css"

const Navigation = () => (
    <div className="navContainer">
        <Link to="/" className="navLink" >Home</Link>
        <span className="navDivider">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <Link to="/about/" className="navLink" >About</Link>
        <span className="navDivider">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <Link to="/service/" className="navLink" >Service</Link>
        <span className="navDivider">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <Link to="/contact/" className="navLink" >Contact</Link>
    </div>
)

export default Navigation